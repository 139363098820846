<!--
 * @Descripttion:公司信息附件上传
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-05 16:31:05
-->
<template>
	<div>
		<van-nav-bar :title="title" left-arrow @click-left="leftAction" />
		<div v-if="show =='1'">
			<div class="flex-between pad">
				<h3 class="f5">营业执照</h3>
				<van-uploader :after-read="afterRead" v-model="fileList1" max-count="1" accept="image/*" :before-read="beforeRead"
				 :max-size="10 * 1024 * 1024" @oversize="onOversize" :preview-full-image="false" :before-delete="beforeDelete" name="busiPicName">
					<button class="btn">上传</button>
				</van-uploader>
			</div>
			<div class="pad-row">
				<img :src="org.busiPic" id="licenceImg" class="w-100">
			</div>
		</div>
		<div v-else-if="show == '2'">
			<div class="flex-between pad">
				<h3 class="f5">法定代表人身份证（正面）</h3>
				<van-uploader :after-read="afterRead" v-model="fileList2" max-count="1" accept="image/*" :before-read="beforeRead"
				 :max-size="10 * 1024 * 1024" @oversize="onOversize" :preview-full-image="false" :before-delete="beforeDelete" name="idPicFrontName">
					<button class="btn">上传</button>
				</van-uploader>
			</div>
			<div class="pad-row">
				<img :src="org.idPicFront" id="front" class="w-100">
			</div>

			<div class="flex-between pad">
				<h3 class="f5">法定代表人身份证（背面）</h3>
				<van-uploader :after-read="afterRead" v-model="fileList3" max-count="1" accept="image/*" :before-read="beforeRead"
				 :max-size="10 * 1024 * 1024" @oversize="onOversize" :preview-full-image="false" :before-delete="beforeDelete" name="idPicBackName"
				 id="orgPicBtn">
					<button class="btn">上传</button>
				</van-uploader>
			</div>
			<div class="pad-row">
				<img :src="org.idPicBack" id="back" class="w-100">
			</div>
		</div>
		<div v-else>
			<div class="flex-between pad">
				<h3 class="f5">公司Logo</h3>
				<van-uploader :max-size="10 * 1024 * 1024" :before-read="beforeRead" :after-read="afterRead" :before-delete="beforeDelete"
				 :preview-full-image="false" max-count="1" accept="image/*" v-model="fileList0" @oversize="onOversize" name="orgPic"
				 id="test">

				</van-uploader>
			</div>
			<div class="pad-row">
				<img :src="org.orgPic" id="orgPath" class="w-100">
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Uploader,
		Field,
		Switch,
		Icon,
		Popup,
		DatetimePicker
	} from 'vant';
	import global from "@/common/global"

	export default {
		data() {
			return {
				title: '营业执照（副本）',
				show: '0',
				files: [],
				org: {
					id: '',
					departName: '',
					legalPerson: '',
					mobile: '',
					usccCode: '',
					orgPic: '',
					busiPic: '',
					tempBusiPic: '',
					idPicFront: '',
					tempIdPicFront: '',
					idPicBack: '',
					tempIdPicBack: ''
				},
				fileList0: [],
				fileList1: [],
				fileList2: [],
				fileList3: [],
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},
		components: {
			[Icon.name]: Icon,
			[Field.name]: Field,
			[Popup.name]: Popup,
			[Switch.name]: Switch,
			[Uploader.name]: Uploader,
			[DatetimePicker.name]: DatetimePicker
		},
		activated() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			if (this.$route.params.type == '2') {
				this.title = '法定代表人身份证'
				this.show = '2'
			} else if (this.$route.params.type == '1') {
				this.title = '营业执照（副本）'
				this.show = '1'
			} else if (this.$route.params.type == '0') {
				this.title = '公司Logo'
				this.show = '0'
			}
			if (this.$ls.get("editOrgTemp")) {
				this.org = this.$ls.get("editOrgTemp");
				console.log(this.$ls.get("editOrgTemp"));
				this.fileList0 = []
				if (this.org.orgPic) {
					this.fileList0.push({
						url: this.org.orgPic
					})
				}
				this.org.busiPic = this.org.busiPic;
				this.fileList1 = [];
				if (this.org.busiPic != '' && this.org.busiPic != null) {
					this.fileList1.push({
						url: this.org.busiPic
					});
				}
				this.org.idPicFront = this.org.idPicFront;
				this.fileList2 = [];
				if (this.org.idPicFront != '' && this.org.idPicFront != null) {
					this.fileList2.push({
						url: this.org.idPicFront
					});
				}
				this.org.idPicBack = this.org.idPicBack;
				this.fileList3 = [];
				if (this.org.idPicBack != '' && this.org.idPicBack != null) {
					this.fileList3.push({
						url: this.org.idPicBack
					});
				}
			}
		},
		methods: {

			//校验图片格式
			beforeRead: function(file) {
				let fileType = ""
				if (file instanceof Array && file.length) {
					for (let i = 0; i < file.length; i++) {
						fileType = file[i].type.substr(0, file[i].type.indexOf("/"))
						if (fileType !== "image") {
							this.$toast("格式错误")
							return false
						}
					}
				} else {
					fileType = file.type.substr(0, file.type.indexOf("/"))
					if (fileType !== "image") {
						this.$toast("格式错误")
						return false
					}
				}
				return true
			},
			//校验文件大小是否超出限制
			onOversize: function() {
				this.$toast.fail("文件太大: 不可超过10M")
			},
			//上传图片
			afterRead: function(file, detail) {
				let tempName = detail.name;
				file.status = "uploading"
				file.message = "上传中..."
				let tempFile = []
				let tempthis = this
				//
				//区分单文件上传还是多文件
				if (file instanceof Array && file.length) {
					for (let i = 0; i < file.length; i++) {
						//formData.append("files", file[i].file)
						tempFile.push(file[i].file)
					}
				} else {
					//formData.append("files", file.file)
					tempFile.push(file.file)
				}
				//上传
				for (let i = 0; i < tempFile.length; i++) {
					let canvas = document.createElement("canvas")
					let context = canvas.getContext("2d")
					let reader = new FileReader()
					let img = new Image()
					img.onload = function() {
						let originw = this.width
						let originh = this.height
						let maxw = 1200
						// let maxh = 640;
						let destw = originw
						let desth = originh
						// 若宽小于高，且宽度超过了1200 则以宽度以1200处理;若宽大于高，宽度以1600处理
						if (originw > originh) maxw = 1600
						if (originw > maxw) {
							// if (originw / originh > maxw / maxh) {
							//     // 更宽，按照宽度限定尺寸
							//     destw = maxw;
							//     desth = Math.round(maxw * (originh / originw));
							// } else {
							//     desth = maxh;
							//     destw = Math.round(maxh * (originw / originh));
							// }
							destw = maxw
							desth = Math.round(maxw * (originh / originw))
						}
						// canvas对图片进行缩放
						canvas.width = destw
						canvas.height = desth
						// 清除画布
						context.clearRect(0, 0, destw, desth)
						// 图片压缩
						context.drawImage(img, 0, 0, destw, desth)
						//上传
						canvas.toBlob(function(blob) {
							let formData = new FormData()
							formData.append("files", blob)

							let openid = tempthis.workweixin?tempthis.openUserid:tempthis.$ls.get("openid")
							let accesstoken = tempthis.$ls.get("token")
							tempthis.$ajax({
								url: "/gateway/ed/external/tbEdSydeEdvasExternal/uploadPic?openid=" +
									openid,
								method: "post",
								headers: {
									"X-Access-Token": accesstoken,
								},
								data: formData,
							}).then((res) => {
								if (res.data.success) {
									file.path = res.data.message;
									file.status = ""
									file.message = ""
									if (tempName == 'orgPic') {
										tempthis.org.orgPic = global.domianURL + file.path;
										tempthis.$ls.set("editOrgTemp", tempthis.org, 10 * 60 * 1000)
										console.log(tempthis.org);
									} else if (tempName == "busiPicName") {
										tempthis.org.busiPic = global.domianURL + file.path;
										tempthis.$ls.set("editOrgTemp", tempthis.org, 10 * 60 * 1000)
									} else if (tempName == 'idPicFrontName') {
										tempthis.org.idPicFront = global.domianURL + file.path;
										tempthis.$ls.set("editOrgTemp", tempthis.org, 10 * 60 * 1000)
									} else if (tempName == 'idPicBackName') {
										tempthis.org.idPicBack = global.domianURL + file.path;
										tempthis.$ls.set("editOrgTemp", tempthis.org, 10 * 60 * 1000)
									}
								} else {
									tempthis.$toast.fail("上传失败")
								}
							})
						}, file.type || "image/jpeg")
					}
					reader.onload = function(e) {
						img.src = e.target.result
					}
					reader.readAsDataURL(file.file)
				}
			},
			leftAction() {
				this.$ls.set("editOrgTemp", this.org, 10 * 60 * 1000)
				this.$router.push("/company")
			},
			//移除照片
			beforeDelete(file, detail) {
				let tempName = detail.name;
				if (tempName == "orgPic") {
					this.org.orgPic = ''
					this.$ls.set("editOrgTemp", this.org, 10 * 60 * 1000)
					return true;
				} else if (tempName == "busiPicName") {
					this.org.busiPic = '';
					this.$ls.set("editOrgTemp", this.org, 10 * 60 * 1000)
					return true;
				} else if (tempName == 'idPicFrontName') {
					this.org.idPicFront = '';
					this.$ls.set("editOrgTemp", this.org, 10 * 60 * 1000)
					return true;
				} else if (tempName == 'idPicBackName') {
					this.org.idPicBack = '';
					this.$ls.set("editOrgTemp", this.org, 10 * 60 * 1000)
					return true;
				}
			},
		}
	}
</script>

<style scoped>
	.pad {
		position: relative;
	}

	.pad input {
		position: absolute;
		right: 0.5rem;
		width: 2rem;
		height: 0.9rem;
		opacity: 0;
	}

	.hide {
		display: ;
	}
</style>
